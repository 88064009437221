import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'

import Login from 'components/modules/Login'
import ForgotPassword from 'components/modules/Login/ForgotPassword'
import PasswordReset from 'components/modules/Login/PasswordReset'
import BaseApp from 'components/shared/BaseApp'
import Layout from 'components/shared/Layout'
import PATHS from 'constants/paths'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout footer={false} />}>
      <Route path={PATHS.login} element={<Login />} />
      <Route path={PATHS.forgotPassword} element={<ForgotPassword />} />
      <Route path={PATHS.resetPassword} element={<PasswordReset />} />
    </Route>,
  ),
)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <BaseApp>
    <RouterProvider router={router} />
  </BaseApp>,
)
