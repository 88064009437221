import ApiService from 'apis/apiService'

export interface GenerateResetPasswordTokenParams {
  email: string
}

const generateResetPassword = ({ email }: GenerateResetPasswordTokenParams) =>
  ApiService.post('/users/password', { user: { email } })

export interface ResetPasswordParams {
  password: string
  passwordConfirmation: string
  resetPasswordToken: string
}

const resetPassword = ({
  password,
  passwordConfirmation,
  resetPasswordToken,
}: ResetPasswordParams) =>
  ApiService.put('/users/password', {
    user: { password, passwordConfirmation, resetPasswordToken },
  })

export default { generateResetPassword, resetPassword }
